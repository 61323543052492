// WheelOfFortune.js
import React, { useState, useRef, useEffect } from "react";
// import { Pie } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { gameSound } from "../../App";
import { showToast } from "../../views/pages/Chess/utils";
import { getSpinStatus, purchaseSpin, spinResult } from "../../service/spinApi";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
// import {useNavigate} from "react-router-dom"
// Register the components needed for Chart.js
// ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const SpinModal = ({
  setFinalValue,
  setSpinModalOpen,
  setSpinResultModalOpen,
  potAmount,
  auth
}) => {
  // let isOn = false;
  const [spinDisabled, setSpinDisabled] = useState(false);
  const [isOn, setIson] = useState(false);
  const [hasSpin, setHasSpin] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [updated, setUpdate] = useState(false);
  const isMountedRef = useRef(true);
  const lables = [0.05, 0.1, 0.5, 1, 2, 5, 10, 15, 20, 50, 100, 250, 500, 1000, 2000];
  // const lableWithTexxt = [`0.05 USDT`, '0.1 USDT', '0.5 USDT', '1 USDT', '2 USDT', '5 USDT', '10 USDT', '15 USDT', '20 USDT', '50 USDT', '100 USDT', '250 USDT', '500 USDT', '1000 USDT'];
  const history = useHistory();
  const wheelRef = useRef(null);

  // Update rotation values for 15 segments
  const rotationValues = Array.from({ length: 15 }, (_, i) => {
    const minDegree = ((360 - 12) / 15) * i;
    const maxDegree = ((360 - 12) / 15) * (i + 1) - 1;
    return { minDegree, maxDegree, value: lables[i] };
  });

  //  console.log("rotationValues>>>",)

  useEffect(() => {
    getSpinStatus()
      .then(res => {
        const resonse = res.data?.result;
        console.log("resonse", resonse);
        setHasSpin(resonse?.isSpin)
      })
  }, [updated])

  const valueGenerator = (angleValue) => {
    for (let i of rotationValues) {
      if (angleValue >= i.minDegree && angleValue <= i.maxDegree) {
        console.log("angleValue", angleValue);
        setTimeout(() => {
          setFinalValue(i.value);
          setSpinResultModalOpen(true);
          setSpinModalOpen(false);
          gameSound.winClap.play();
          spinResult(+i.value)
            .then(res => {
              auth.getUserWallletApi();
              setUpdate(false)
            })
        }, 2000);
        setSpinDisabled(false);
        break;
      }
    }
  };



  // handle get spin degree
  const getSpinDegree = () => {
    let thresholdVal = 0;
    const potVal = potAmount;
    rotationValues.forEach(data => {
      if (data.value <= potVal) {
        thresholdVal = data;
      }
    });
    const THRESHOLD_VAL = thresholdVal.maxDegree
    const result = 1 + Math.floor(Math.random() * (THRESHOLD_VAL - 12));
    
    // const final
    return result;
  }


  // handle Sping
  const handleSpin = () => {
    if (hasSpin === false) {
      showToast('error', "Uh oh! Sorry but you don't have lucky spin yet!");
      return;
    }

    setSpinDisabled(true);
    setFinalValue("Good Luck!");
    const speenWheel = document.querySelector("#spin-wheel");
    speenWheel.classList.add("wheel-spin");
    gameSound.spinStart.play();

    let randomDegree = getSpinDegree();

    if (randomDegree < 60) {
      randomDegree = 60;
    }

    if (wheelRef.current) {
      // const myChart = wheelRef.current;
      setTimeout(() => {
        gameSound.spinStart.pause();
        gameSound.spinEnd.play();
        speenWheel.style.animationDuration = `1.5s`;
      }, 1500);

      setTimeout(() => {
        speenWheel.classList.remove("wheel-spin");
        gameSound.spinStart.pause();
        gameSound.spinEnd.pause();
        speenWheel.style.transform = `translateY(-11px) rotate(-${randomDegree}deg)`;
        valueGenerator(randomDegree);
      }, 2950);
      console.log("randomDegree", randomDegree);
      console.log("rotationValues2", rotationValues, randomDegree);
    }
  };


  useEffect(() => {
    isMountedRef.current = true; // Component is mounted

    let interval = null;
    const circles = document.querySelectorAll(".light-circle");

    function addStyle() {
      interval = setInterval(() => {
        circles.forEach((el, i) => {
          setTimeout(() => {
            if (isMountedRef.current) { // Check if component is still mounted
              if (i % 2 !== 0) {
                if (isOn) {
                  el.style.background = "#a2a4a8";
                  el.style.boxShadow = "0 0 5px 0 lightgray";
                  setIson(false);
                } else {
                  el.style.background = "white";
                  el.style.boxShadow = "0 0 15px 0 white";
                  setIson(true);
                }
              } else {
                if (isOn) {
                  el.style.background = "#a2a4a8";
                  el.style.boxShadow = "0 0 5px 0 lightgray";
                  setIson(false);
                } else {
                  el.style.background = "white";
                  el.style.boxShadow = "0 0 15px 0 white";
                  setIson(true);
                }
              }
            }
          }, i % 2 !== 0 ? 300 : 100);
        });
      }, 600);
    }

    addStyle();

    return () => {
      clearInterval(interval); // Cleanup interval on unmount
      isMountedRef.current = false; // Component is unmounted
    };
  }, [isOn]);


  // handle purchase spin
  const handlePurchaseSpin = () => {
    if (parseFloat(auth?.viewWalletData?.balance).toFixed(2) <= 0) {
      showToast('error', 'insufficient Balanace');
      return;
    }

    setisLoading(true);
    purchaseSpin()
      .then(res => {
        setTimeout(() => {
          setisLoading(false);
          auth.getUserWallletApi();
          setUpdate(!updated)
          showToast('success', 'Congratulations, the spin amount has been successfully added.')
        }, 2000)
      })
  }

  return (
    <div className="spin-modal">
      <div className='spinCloseButton' onClick={() => {
        setSpinModalOpen(false)
        gameSound.spinStart.pause();
        gameSound.spinEnd.pause();
        gameSound.winClap.pause();
        history.push("/")
      }} >
        <img src="/images/closeIcon.svg" alt="closeIcon" width={30} />
      </div>
      <div className="wheel-container relative rounded-full">
        {Array.from({ length: 12 }, (_, i) => (
          <div
            className={`light-circle light-circle-${i + 1}`}
            key={`light-circle-${i}`}
          ></div>
        ))}
        {/* <Pie
          id="spin-wheel"
          ref={wheelRef}
          data={data}
          options={{
            title: { display: false },
            legend: { display: false },
            responsive: true,
            animation: { duration: 0, animateScale: true },
            plugins: {
              tooltip: false,
              legend: {
                display: false,
              },
              datalabels: {
                color: "#ffffff",
                formatter: (_, context) =>
                  context.chart.data.labels[context.dataIndex],
                font: { size: 16,weight: 'bold', },
                textShadow: '2px 2px 10px #000',
                anchor: "end", // Position of the label relative to the data point
                align: "start", // Alignment of the label relative to the anchor
                rotation: (context) => getRotation(context.dataIndex), // Rotate labels 45 degrees
              },
            },
          }}
        /> */}
        <img
          src="/images/games/spinWheel.png"
          alt="spinWhell"
          id="spin-wheel"
          ref={wheelRef}
        />

        <img
          src="/images/games/spinArrow.png"
          alt="/images/games/spinArrow.png"
          className="w-12 h-12 object-contain absolute top-0 z-[9]"
        />
        <img
          onClick={handleSpin}
          src="/images/games/spinCenter.png"
          alt="/images/games/spinArrow.png"
          className={
            `hover:scale-[.98] active:scale-[.95] cursor-pointer spin-center w-36 h-36 object-contain absolute top-[48%] left-[50%] -translate-y-1/2 -translate-x-1/2 z-[9] 
             ${spinDisabled ? 'pointer-events-none' : 'pointer-events-auto'}
            `
          }
        />
        <img
          src="/images/games/spinRing.png"
          alt="/images/games/spinring.png"
          className="spin-ring"
        />
        {/* <div className="bg-black/90 blur-md rounded-full w-28 h-28 object-contain absolute top-[48%] left-[50%] -translate-y-1/2 -translate-x-1/2 z-[8]"></div> */}
      </div>
      <div className="py-3 mt-10">
        {
          isLoading ? <CircularProgress color="secondary" /> : (
            <button
              onClick={handlePurchaseSpin}
              className={`
              purchase-button font-normal text-sm active:scale-[.98] hover:scale-[.98]
              ${!hasSpin ? 'grayscale-0 pointer-events-auto' : 'opacity-30 grayscale pointer-events-none'}
            `}
            >
              Purchase Spin - 10 USDC
            </button>
          )
        }
      </div>
    </div>
  );
};

export default SpinModal;
