import React, { useCallback, useEffect, useState } from "react";
import { publish, subscribe, unsubscribe } from "../CustomEvents/event";
import { OnLocalRoomListUpdate, OnRoomListing, RoomListing, ludo_onCreateRoom } from "../constants/socketKeys";
import { useDispatch, useSelector } from "react-redux";
import { setIsCreated } from "../feature/slice/gameSlice";

export function useRoomListing(modalShow,selected) {
  const {isCreated} = useSelector(state => state.game);
  const [rooms, setRooms] = useState([]);
  const dispatch = useDispatch();

   // TODO handling socket events
   const onHandleCreateRoom = useCallback(() => {
    // // console.log("onHandleCreateRoom", data);
    dispatch(setIsCreated(true));
    publish(RoomListing);
  },[dispatch]); 

  const roomListing  = () => {
   
      publish(RoomListing);
    
  }
  
  useEffect(() => {
    const fetchRoomList =  async (data) => {
      var rooms = data.detail.data;
      // console.log("roomslist>>",rooms)
      const openRooms = rooms.filter((room) => {
        if (room?.maxPlayers !== room?.totalPlayers) {
          return room;
        }
      });
      setRooms(openRooms);
    }

    subscribe(OnRoomListing, fetchRoomList);
    // subscribe(ludo_onCreateRoom, onHandleCreateRoom);
    subscribe(OnLocalRoomListUpdate, roomListing);

    // fetch room listing
    if (modalShow || isCreated || selected) {
      // //;
      publish(RoomListing);
    }
    return () => {
      unsubscribe(OnRoomListing,fetchRoomList);
      unsubscribe(ludo_onCreateRoom, onHandleCreateRoom);
      unsubscribe(OnLocalRoomListUpdate, roomListing);
    };
  }, [modalShow, isCreated, onHandleCreateRoom, selected]);
  return [rooms];
}
