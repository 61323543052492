import ApiConfig from '../ApiConfig/ApiConfig.js';
import axios from 'axios';

export const getSpinStatus = async () => {
    try {
        return await axios({
            method: 'GET',
            url: ApiConfig?.getSpinStatusApi,
            headers: {
                token: window.sessionStorage.getItem("token"),
            },
        });
    } catch (error) {
        console.log(error);
        return error?.response;
    }
}
export const getPotAmountApi = async () => {
    try {
        return await axios({
            method: 'GET',
            url: ApiConfig?.getSpinPotAmount,
            headers: {
                token: window.sessionStorage.getItem("token"),
            },
        });
    } catch (error) {
        console.log(error);
        return error?.response;
    }
}

export const spinResult = async (winAmount) => {
    try {
        return await axios({
            method: 'POST',
            url: ApiConfig?.spinResultApi,
            headers: {
                token: window.sessionStorage.getItem("token"),
            },
            data: {
                "isWin": true,
                "potAmount": winAmount
            }
        });
    } catch (error) {
        console.log(error);
        return error?.response;
    }
}

export const purchaseSpin = async () => {
    try {
        return await axios({
            method: 'POST',
            url: ApiConfig?.purchaseSpinApi,
            headers: {
                token: window.sessionStorage.getItem("token"),
            },
            data: {
                "amount": 10
            }
        });
    } catch (error) {
        console.log(error);
        return error?.response;
    }
}