import React from 'react'

import { memo, useMemo } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import Text from "../../../../components/Wrapper/Text";
import ListItem from "../List/ListItem";

import {
  setGameObj,
  setSingleLobbyData,
} from "../../../../feature/slice/gameSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRoomListing } from "../../../../hooks/useRoomListing";
import { useHistory } from "react-router-dom";
import { getRandomeColorForTowPlayers } from "../../../../helper/game";
import { resetSessionStorage } from "../../../../utils/sessionStorageUtils";

// eslint-disable-next-line react/prop-types
function JoinRoomM({ modalShow, onClose, onOpenSelectPawn }) {
  const [rooms] = useRoomListing(modalShow);
  const dispatch = useDispatch();
  const navigate = useHistory();

  console.log("rooms>>>",rooms)

  const filterList = useMemo(() => {
    return rooms
      ?.filter((room) => {
        // console.log("room>>",room);
        const isVisbile = room?.properties?.isVisible || true;
        const isOpen = room?.properties?.isOpen || true;
        if (isVisbile && isOpen) {
          return room;
        }
      })
      .sort((a, b) => b.createTime - a.createTime);
  }, [rooms]);

  const handleJoinRoom = (roomData) => {
    const joinRoomData = {
      roomname: roomData?.roomname,
      nickname: "user00" + `${Math.floor(Math.random() * 100)}`,
    };

    // publish(joinRoom, joinRoomData);
    //** clear out session */
    resetSessionStorage();

    // ** navigate into game
    navigate.push(`/ludo/gameEnter?room=${joinRoomData?.roomname}`, {
      replace: true,
    });
  };

  // console.log("rooms?>>>", rooms);
  //** Handle Join Room */
  const handleRoomJoin = (roomData) => {
    const maxPlayers = roomData?.maxPlayers;
    if (maxPlayers === 2) {
      const boardType = roomData?.roomCustomPropertiesForLobby?.boardType;
      const roomAmmount = roomData?.roomCustomPropertiesForLobby?.roomAmmount;
    
      const exstingColor = roomData?.roomCustomPropertiesForLobby?.tokenArr[0];
      const getColor = getRandomeColorForTowPlayers(exstingColor);
      dispatch(setGameObj({ token: getColor, selectedBoard: boardType, feeAmmount : roomAmmount }));
      handleJoinRoom(roomData);
    } else {
      onOpenSelectPawn();
    }
    dispatch(setSingleLobbyData(roomData));
    onClose();
  };

  return (
    <>
      <ModalWrapper
        visible={modalShow}
        onClose={onClose}
        customStyles={{
          width: "30%",
          height: "auto",
          maxHeight: "70%",
        }}
        title="Rooms"
        id="join-room-modal"
      >
        <div className=" h-5/6 overflow-y-auto">
          {filterList?.length == 0 && (
             <>
               <div className="h-5/6 grid place-items-center">
                <Text
                  title="There is no rooms available."
                  color="text-zinc-200 text-xl text-center"
                />
              </div>
             </>
          )}
          <>
          <ul>
            {filterList.length > 0 &&
              filterList.map((roomData, idx) => (
                <>
                  <ListItem
                  key={roomData?.roomname}
                  serial={idx}
                  isVisible={roomData?.isVisible}
                  isOpen={roomData?.isOpen}
                  name={roomData?.roomname}
                  players={roomData?.totalPlayers}
                  onJoin={() => handleRoomJoin(roomData)}
                  roomAmmount={roomData?.roomCustomPropertiesForLobby?.roomAmmount}
                />
                </>
              ))}
          </ul>
          </>
        </div>
      </ModalWrapper>
    </>
  );
}

export default memo(JoinRoomM);
