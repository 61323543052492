/* eslint-disable react/prop-types */
import React, { useState } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import Text from "../../../../components/Wrapper/Text";
import Button from "../../../../components/button/Button";
import joinroomText from "../../../../assets/JoinRoomText.svg";
import { publish } from "../../../../CustomEvents/event";
import { ludo_joinRoom } from "../../../../constants/socketKeys";
import { resetSessionStorage } from "../../../../utils/sessionStorageUtils";
import { JoinPoolApi } from "../../../../feature/service/gameService";
import { showToast } from "../../../../utils";
import Spinner from "../../../../components/Spinner/Spinner";

function JoinPrivateRoomModal({ modalShow, onClose,setRoomCode,isPending,setPending}) {
  
  const [formData, setFormData] = useState({
    roomCode: null,
    roomPassword: null,
  });

  //** handle Change */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //** joi pool */
  const JoinPool = (payload,code) => {
    publish(ludo_joinRoom,{
      roomname: code,
      nickname: 'user00' + Math.floor(Math.random() * 100),
      roomPassword : "abc123"
    });
  }

  //** handle join private room */
  const handleJoinPrivateRoom = (e) => {
    e.preventDefault();
   
    setPending(true);

    // ** set room code
    setRoomCode(formData?.roomCode);

    // ** payload
    const payload = {
      poolId : formData?.roomCode,
      gameFees : "1000",
      matchCount : 1
    }

    JoinPool(payload,formData?.roomCode);
    
    // reset form data
    setFormData({
      roomCode: '',
      roomPassword: '',
    })

    resetSessionStorage();
    
}
  
  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "30%",
        height: "auto",
        maxHeight: "40%",
      }}
      title="Join"
      id="join-private-room-modal"
      enterAnimation={"zoom"}
      leaveAnimation={"zoom"}
    >
      <form 
       onSubmit={handleJoinPrivateRoom}
       className="p-4 h-5/6 overflow-auto text-center flex flex-col gap-4 justify-center items-center bg-transparent"
      >
        {/* room code */}
        <Text size="2xl" title="Enter Private Code" color="text-white" />
        <input
          type="number"
          name="roomCode"
          value={formData?.roomCode}
          onChange={handleChange}
          className="text-center p-3 text-[#fff] w-11/12 bg-transparent border-none outline outline-2 outline-[#ffb702] rounded-lg shadow-lg shadow-yellow-400/40"
          required={true}
        />

        {/* password */}
        {/* <Text size="2xl" title="Enter Password" color="text-white" />
        <input
          type="text"
          name="roomPassword"
          onChange={handleChange}
          className="p-3 text-[#fff] w-9/12 bg-transparent border-none outline outline-2 outline-[#FFB902] rounded-lg shadow-lg shadow-yellow-400/40"
        /> */}
        
        {
          isPending ? <Spinner /> : (
            <Button
              type="submit"
              text={
                <div className="text-shadow uppercase text-xl">
                  {/* <img src={joinroomText} alt={joinroomText} /> */}
                  join
                </div>
              }
              className="play py-2 text-sm md:text-xl  mt-3 "
              // handleClick={handleNext}
            />
          )
        }
        
      </form>
    </ModalWrapper>
  );
}

export default JoinPrivateRoomModal;
