import moveAudio from "../assets/sounds/moveSound.mp3";
import captureAudio from "../assets/sounds/capture.mp3";
import checkAudio from "../assets/sounds/move-check.mp3";
import loosing from '../assets/sounds/loosing.wav';
import winning from '../assets/sounds/winnigSound.mp3'
import { Howl, Howler } from "howler";
export class GameAudio {
  constructor() {
    this.moveAudio = new Howl({
      src: [moveAudio],
    });
    this.captureAudio = new Howl({
      src: [captureAudio],
    });
    this.winningSound = new Howl({
      src: [winning],
    });
    this.loosingAudio = new Howl({
      src: [loosing],
    });
  }
  playMoveAudio() {
    this.moveAudio.play();
  }

  playCaptureAudio() {
    this.captureAudio.play();
  }


  playWinAudio() {
    this.winningSound.play();
  }

  playLooseAudio() {
    this.loosingAudio.play();
  }


  playCheckAudio() {
    const isSoundEnabled = sessionStorage.getItem("isSoundEnabled");
    if (isSoundEnabled === "true") {
      let audio = new Audio(checkAudio);
      audio.play();
    }
  }

  updateVolume(val) {
    console.log("val", val)
    this.moveAudio.volume(val);
    this.captureAudio.volume(val);

    // console.log("this.moveAudio.volume",this.moveAudio.volume)
    // this.cutAudio.volume = val;
    // this.swipAudio.volume = val;
    // this.pawnMove.volume = val;
    // Howler.volume(val);
 }

 updateSound(val) {
  console.log("val", val)
  
  this.winningSound.volume(val)
  this.loosingAudio.volume(val)
  // this.pawnMove.volume = val;
  // Howler.volume(val);
}
}


